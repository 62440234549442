import React, { useState, useEffect } from 'react';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';

import axios from 'axios';
import {mainUrl} from '../../MainUrl';
import DataTable from 'react-data-table-component';

import {
    Row, Col, Card, CardHeader, CardBody
} from 'reactstrap';
import ExcelExport from '../../component/utility/ExcelExport';


const BuyerList = () => {

    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [buyerList, setBuyerList] = useState('');
    const [farmerList, setFarmerList] = useState('');

    useEffect(()=>{
        get_buyer_list();
    },[]);

    const get_buyer_list = () => {
        
        axios.post(mainUrl + 'mis/getBuyerList')
            .then(function (response) {
                if (response.data.status === 1) {

                    let result = response.data.data;

                    let list = result.map((row, index) => {
                        return(
                            {
                                sl: index + 1,
                                buyer_code: row.buyer_code,
                                org_name: row.org_name,
                                contact_person: row.name,
                                designation: row.designation_name,
                                mobile_no: row.mobile_no,
                                reg_date: row.reg_date,
                            }
                        )
                    })

                    setBuyerList(list);
                    
                }
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    return (
        <div>
            <Header />
            <Page>
                <Row>
                    {
                        mode === 1 &&
                        <Col xs={12}>
                            <h5 className='text-primary'>Buyer List</h5>
                            <Card color="primary" outline>
                                <CardHeader className='d-flex justify-content-between'>
                                    <div>All Buyer</div>
                                    { (buyerList && buyerList.length > 0) &&
                                        <ExcelExport data={buyerList} name="Buyer_list" />
                                    }
                                </CardHeader>
                                <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: '#',
                                            selector: row => row.sl,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Buyer Code',
                                            selector: row => row.buyer_code,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Organization',
                                            selector: row => row.org_name,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Contact Person',
                                            selector: row => row.contact_person,
                                            sortable: false,
                                            wrap: true,
                                        },
                                        {
                                            name: 'Designation',
                                            selector: row => row.designation,
                                            sortable: false,
                                            wrap: true,
                                        },
                                        {
                                            name: 'Mobile No',
                                            selector: row => row.mobile_no,
                                            sortable: false,
                                            wrap: true,
                                        },
                                        {
                                            name: 'Reg Date',
                                            selector: row => row.reg_date,
                                            sortable: false,
                                            wrap: true,
                                        },
                                    ]}
                                    data={buyerList}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}

                                />
                                </CardBody>
                            </Card>
                        </Col>
                    }
                   
                </Row>
            </Page>
            <Footer />
        </div>
    )
}

export default BuyerList;