import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Logo from './../../assets/img/logo.jpeg';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody
} from 'reactstrap';

import Avatar from '@mui/material/Avatar';

function Header() {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(1);
    const [menu, setMenu] = useState(false);
    const [m1, setM1] = useState(false);
    const [m2, setM2] = useState(false);
    const [m3, setM3] = useState(false);

    const signout_handler = () => {
        sessionStorage.clear();
        navigate("/");
    }

    return (
        <>
            <div className='headerPage'>
                <Row>

                    <Col xs={6} className="p-2">
                        {/* ------------------ In Laptop Menu  -------------------- */}
                        <div className='justify-content-start align-items-center h-100 d-none d-md-flex'>
                            <div className='me-3'>
                                <Avatar alt="img" className="ms-3" src={require('../../assets/img/user.jpg')} />

                            </div>
                            <div className='d_menu'>
                                <Link to="/home"> Home</Link>
                            </div>
                            <Dropdown isOpen={m1} onMouseEnter={() => setM1(true)} onMouseLeave={() => setM1(false)}>
                                <DropdownToggle
                                    className={`d_menu ${m1 && 'bg-primary text-white'}`}
                                    data-toggle="dropdown"
                                    tag="span"
                                >
                                    FPO
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div className='d_menuItem first' onClick={() => setM1(false)}>
                                        <Link to="/fpo-List">FPO List</Link>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isOpen={m2} onMouseEnter={() => setM2(true)} onMouseLeave={() => setM2(false)}>
                                <DropdownToggle
                                    className={`d_menu ${m2 && 'bg-primary text-white'}`}
                                    data-toggle="dropdown"
                                    tag="span"
                                >
                                    Buyer
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div className='d_menuItem first' onClick={() => setM2(false)}>
                                        <Link to="/buyer-List">Buyer List</Link>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isOpen={m3} onMouseEnter={() => setM3(true)} onMouseLeave={() => setM3(false)}>
                                <DropdownToggle
                                    className={`d_menu ${m3 && 'bg-primary text-white'}`}
                                    data-toggle="dropdown"
                                    tag="span"
                                >
                                    Master
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div className='d_menuItem first' onClick={() => setM3(false)}>
                                        <Link to="/buyer-Mapping">Buyer-Mapping</Link>
                                    </div>
                                    <div className='d_menuItem first' onClick={() => setM3(false)}>
                                        <Link to="/daily-dispatch-milk">Daily-Dispatch-Milk</Link>
                                    </div>
                                    <div className='d_menuItem first' onClick={() => setM3(false)}>
                                        <Link to="/daily-milk-transaction">Milk Transaction</Link>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>

                            <div className='d_menu'>
                                <a className='cursor' onClick={signout_handler}> Signout </a>
                            </div>

                        </div>
                        {/* ------------------ In Mobile Menu  -------------------- */}
                        <div className='d-flex d-md-none justify-content-start align-items-center h-100'>

                            <Avatar alt="img" className="ms-3 cursor" src={require('../../assets/img/user.jpg')} onClick={() => setMenu(!menu)} />

                            <Offcanvas
                                direction="end"
                                scrollable
                                isOpen={menu}
                            >
                                <OffcanvasHeader toggle={() => setMenu(!menu)}>
                                    Menu
                                </OffcanvasHeader>
                                <div className='border-bottom' style={{ fontSize: '16px' }}>
                                    <div className='text-primary ps-4'><b>i-Milker</b></div>

                                    <small className='d-block ps-4'>Role: Admin</small>
                                    <small className='d-block pb-1 ps-4'>Address: Pune, Maharashtra-411006</small>

                                </div>
                                <OffcanvasBody>
                                    <Accordion
                                        flush
                                        open={openMenu}
                                        toggle={(id) => setOpenMenu(id)}
                                    >
                                        <AccordionItem>
                                            <AccordionHeader targetId="0">
                                                <Link to="/home">Home</Link>
                                            </AccordionHeader>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionHeader targetId="1">
                                                Master
                                            </AccordionHeader>
                                            <AccordionBody accordionId="1" className='position-relative'>

                                                <div className='m_menuItem first'>
                                                    <Link to="/registration">Farmer Registration</Link>
                                                </div>

                                                <div className='m_menuItem'>
                                                    <Link to="/page_2">Page - 2</Link>
                                                </div>
                                            </AccordionBody>
                                        </AccordionItem>

                                        <div className='cursor ps-3 fw-bold text-secondary' style={{ fontSize: "18px" }} onClick={signout_handler}>signout</div>
                                        <hr />
                                    </Accordion>
                                </OffcanvasBody>
                            </Offcanvas>
                        </div>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center justify-content-end">
                        <img className='pe-4 ' height="60px" src={Logo} alt="logo" />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Header;