import React, {useEffect} from 'react';

import Header from './../component/layout/Header';
import Footer from './../component/layout/Footer';

import {
    Row, Col,
} from 'reactstrap';


const Home = () => {
    

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <div>
            <Header />
            
            <div className='borderBottom'></div>

            {/* ----------------- Top section Home page ----------------- */}

            <Row className="p-4 hometopPage" style={{marginTop:"64px"}}>
                <Col md="4" className="text-center mt-2">
                   <img src={require('../assets/img/logo_big.png')} alt="img" />
                </Col>
                
            </Row>
            <div className='borderBottom'></div>

            <Footer />
        </div>
    )
}

export default Home;