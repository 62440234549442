
import { Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Home from './pages/Home';
import WIP from './pages/WIP';

// FPO Pages
import FpoList from "./pages/fpo/FpoList";
// Buyer Pages
import BuyerList from "./pages/buyer/BuyerList";
// Master Pages
import BuyerMapping from "./pages/master/BuyerMapping";
import DailyDispatchMilk from "./pages/master/DailyDispatchMilk";
import DailyMilkTransaction from "./pages/master/DailyMilkTransaction";
//
import FarmerRegistration from "./pages/master/FarmerRegistration";
import Page_2 from "./pages/master/Page_2";
//

import './style/main.scss';

function App() {
  return (
    <div className="App" >
      <Routes>
        <Route path="/" element={ <Login /> } />
        <Route path="/home" element={ <Home /> } />

        <Route path="/fpo-List" element={ <FpoList /> } />

        <Route path="/buyer-List" element={ <BuyerList /> } />

        <Route path="/buyer-Mapping" element={ <BuyerMapping /> } />
        <Route path="/daily-dispatch-milk" element={ <DailyDispatchMilk /> } />
        <Route path="/daily-milk-transaction" element={ <DailyMilkTransaction /> } />

        <Route path="/registration" element={ <FarmerRegistration /> } />
        <Route path="/page_2" element={ <Page_2 /> } />
        <Route path="*" element={ <WIP /> } />
      </Routes>
    </div>
  );
}

export default App;