
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {notification} from '../component/hocs/notification';
//import axios from 'axios';
//import mainUrl from '../MainUrl';

import {
  Col, Row, Button, Form, FormGroup, Input, Label, Spinner
} from 'reactstrap';

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    let postData = [{
      "user_id": userId,
      "user_pwd": password,
    }];
    
    if(userId === "admin" && password === "1234"){
      setLoading(false);
      let msg = `Welcome to i-Milk MIS.`;
      let notify = notification({ message: msg, type: 'success' });
      notify();
      
      navigate('/home');
    }else{
      setLoading(false);
      let msg = `User id and password not matched! Try again.`;
      let notify = notification({ message: msg, type: 'error' });
      notify();
    }
    /*axios({
      method: 'post',
      url: mainUrl + 'Master/box_user_login',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
  })
      .then(function (response) {
            let msg = `Welcome to i-Milk.`;
            let notify = notification({ message: msg, type: 'success' });
            notify();
            
            navigate('/home');
            setLoading(false);

      }).catch(error => {
          setLoading(false);
          let notify = notification({ message: "User id and password not matched! Try Again", type: 'error' });
          notify();
      })*/
  };

  return (
    <Row className="d-flex justify-content-center align-items-center br-5 loginPage">
      <Col md={4} className="p-3 rounded" style={{ backgroundColor: '#FFF', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'}} >
            <Form className="mb-3" onSubmit={handleSubmit}>
              <div className="text-center pb-2">
                <h5 className="text-center text-primary"><b>I-Milk Login</b></h5>
              </div>
              <FormGroup>
                <Label for="userName">User Name</Label>
                <Input bsSize="sm" type="text" name='userName' placeholder='User name' onChange={(e) => setUserId(e.target.value)} required />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input bsSize="sm" type="password" name='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} required />
              </FormGroup>
              <hr />
              <Button
                size="sm"
                color="primary"
                block
                disabled= {loading}
                type="submit">Login <span>{loading && <Spinner color="secondary" size="sm" >Load...</Spinner>}</span>
              </Button>
            </Form>
            <small className="d-block mb-1 text-muted text-center">© All Rights Reserved By I-Milk, 2022</small>
      </Col>


    </Row>
  );
}

export default Login;
