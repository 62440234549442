import React, { useState, useEffect } from 'react';
import { notification } from '../../component/hocs/notification';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';

import Select from 'react-select';

import axios from 'axios';
import { mainUrl } from '../../MainUrl';
import DataTable from 'react-data-table-component';

import {
    Row, Col, Card, CardHeader, CardBody, Button, CardFooter, Label, Input
} from 'reactstrap';
import ExcelExport from '../../component/utility/ExcelExport';


const BuyerMapping = () => {

    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [buyerList, setBuyerList] = useState('');
    const [fpoList, setFpoList] = useState('');
    const [farmerList, setFarmerList] = useState('');
    const [fpo, setFpo] = useState('');
    const [currBuyer, setCurrBuyer] = useState('');
    const [currBuyerName, setCurrBuyerName] = useState('');
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedFarmers, setSelectedFarmers] = useState([]);

    const [excelData, setExcelData] = useState([]);

    useEffect(() => {
        get_buyer_list();
        get_fpo_list();
        ;
    }, []);

    const get_fpo_list = () => {

        axios.post(mainUrl + 'mis/getFpoList')
            .then(function (response) {
                if (response.data.status === 1) {
                    let result = response.data.data;

                    let list = result.map(row => {
                        return (
                            {
                                value: row.fpo_code,
                                label: row.fpo_name
                            }
                        )
                    })

                    setFpoList(list);

                }
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_farmer_list = (opt) => {
        setFpo(opt.value);

        let postData = { "fpo_code": opt.value, "buyer_code": currBuyer }
        axios.post(mainUrl + 'mis/getMappingFarmerList', postData)
            .then(function (response) {
                if (response.data.status === 1) {
                    let result = response.data.data;

                    let list = result.map((row, index) => {
                        return (
                            {
                                ids: index + 1,
                                fpoCode: row.fpo_code,
                                fpoName: row.fpo_name,
                                farmerCode: row.farmer_code,
                                farmerName: row.name,
                                mapped: row.mapped,
                                status: row.mapped === 1 ? 
                                    <span className='text-success'>Mapped</span> :
                                    <span className='text-danger'>Map Farmer to Buyer</span>
                            }
                        )
                    })
                    setFarmerList(list);
                    // export excel data
                    let e_list = result.map((row, index) => {
                        return (
                            {
                                "SL No.": index + 1,
                                "FPO Code": row.fpo_code,
                                "FPO Name": row.fpo_name,
                                "Farmer Code": row.farmer_code,
                                "Farmer Name": row.name,
                                "Status": row.mapped === 1 ? `Mapped with ${currBuyerName}` : "Not Mapped",
                            }
                        )
                    })
                    setExcelData(e_list);
                }
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_buyer_list = () => {

        axios.post(mainUrl + 'mis/getBuyerList')
            .then(function (response) {
                if (response.data.status === 1) {

                    let result = response.data.data;

                    let list = result.map((row, index) => {
                        return (
                            {
                                sl: index + 1,
                                buyer_code: row.buyer_code,
                                org_name: row.org_name,
                                contact_person: row.name,
                                designation: row.designation_name,
                                mobile_no: row.mobile_no,
                                reg_date: row.reg_date,
                                action: <Button color='primary' size="sm" onClick={() => goto_mapping_handler(row)}> Mapping </Button>
                            }
                        )
                    })

                    setBuyerList(list);

                }
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const goto_mapping_handler = (row) => {
        setCurrBuyer(row.buyer_code);
        setCurrBuyerName(row.org_name);
        setMode(2);
    }

    const add_mapping_handler = () => {
        setLoading(true);
        let flag = true;
        //console.log(selectedFarmers);
        if(selectedFarmers.length === 0){
            flag = false;
            let notify = notification({ message: "Select at least one farmer.", type: 'warn' });
            notify();
            setLoading(false);
        }

        let postData = {
            "fpo_code": fpo,
            "buyer_code": currBuyer,
            "farmers": selectedFarmers,
        }
        if(flag){
            axios.post(mainUrl + 'mis/buyerMapping', postData)
                .then(function (response) {
                    if (response.data.status === 1) {
                        let notify = notification({ message: "Farmers mapped to buyer", type: 'success' });
                        notify();
                        setLoading(false);
                        get_farmer_list({value: fpo})
    
                    }
                })
                .catch(error => {
                    setLoading(false);
                    //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                    //notify();
                });
        }
    }

    const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);

        let temp = state.selectedRows.map(row => {
            return({"farmerCode": row.farmerCode})
        })
        setSelectedFarmers(temp);
        
	}, []);

    const rowDisabledCriteria = row => row.mapped===1;

    return (
        <div>
            <Header />
            <Page>
                <Row>
                    {
                        mode === 1 &&
                        <Col xs={12}>
                            <h5 className='text-primary'>Buyer Mapping</h5>
                            <Card color="primary" outline>
                                <CardHeader>All Buyer</CardHeader>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                            },
                                            {
                                                name: 'Buyer Code',
                                                selector: row => row.buyer_code,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Organization',
                                                selector: row => row.org_name,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Contact Person',
                                                selector: row => row.contact_person,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Designation',
                                                selector: row => row.designation,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Mobile No',
                                                selector: row => row.mobile_no,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Reg Date',
                                                selector: row => row.reg_date,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Action',
                                                selector: row => row.action,
                                                sortable: false,
                                            },
                                        ]}
                                        data={buyerList}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    }

                    {
                        mode === 2 &&

                        <Col xs={12}>
                            <h5 className='text-primary'>Buyer Mapping Screen</h5>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Row>
                                        <Col xs={3}>
                                            <Label for="shareholder"> Buyer Name </Label>
                                            <Input type="text" value={currBuyerName} readOnly />
                                        </Col>
                                        <Col xs={3}>
                                            <Label for="shareholder"> Fpo List </Label>
                                            <Select closeMenuOnSelect={true} isMulti={false}
                                                //value={fpo}
                                                options={fpoList}
                                                onChange={(opt) => get_farmer_list(opt)}
                                                placeholder= "Select FPO"
                                                required />
                                        </Col>
                                        <Col xs={6} className='text-end'>
                                            { ( excelData && excelData.length > 0) &&
                                                <ExcelExport data={excelData} name="Buyer_mapping" classes="btn-margin" />
                                            }
                                            <Button className='btn-margin ms-3' color='secondary' size="sm" onClick={()=>{setMode(1); setFarmerList([]);}}> Back </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card color="primary" outline>
                                <CardHeader>Mapping List</CardHeader>
                                <CardBody>
                                <DataTable
                                        title=""
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.ids,
                                                sortable: true,
                                            },
                                            {
                                                name: 'FPO Code',
                                                selector: row => row.fpoCode,
                                                sortable: false,
                                            },
                                            {
                                                name: 'FPO Name',
                                                selector: row => row.fpoName,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Farmer Code',
                                                selector: row => row.farmerCode,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Farmer Name',
                                                selector: row => row.farmerName,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Status',
                                                selector: row => row.status,
                                                sortable: false,
                                                wrap: true,
                                            }
                                        ]}
                                        data={farmerList}
                                        striped
                                        selectableRows
                                        onSelectedRowsChange={handleRowSelected}
                                        selectableRowDisabled={rowDisabledCriteria}
                                        highlightOnHover
                                        progressPending={loading}
                                    />

                                        <div className='mt-3 text-end'>
                                            <Button className='btn-margin' color='primary' size="sm" onClick={add_mapping_handler} disabled={loading}> Mapped Farmer to Buyer </Button>
                                        </div>

                                </CardBody>
                            </Card>
                        </Col>
                    }

                </Row>
            </Page>
            <Footer />
        </div>
    )
}

export default BuyerMapping;