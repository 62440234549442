import React, { useState } from 'react';

import Header from './../../component/layout/Header';
import Footer from './../../component/layout/Footer';
import Page from './../../component/layout/Page';
import { notification } from '../../component/hocs/notification';

import {BsFillArrowRightCircleFill} from 'react-icons/bs';

import {
    Row, Col, Card, CardHeader, CardBody, Form, Label, Input, Button,
} from 'reactstrap';


const FarmerRegistration = () => {
    const [fpoName, setFpoName] = useState('');
    const [fName, setFName] = useState('');
    const [designation, setDesignation] = useState('');
    const [gender, setGender] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [block, setBlock] = useState("");
    const [panchayat, setPanchayat] = useState("");
    const [village, setVillage] = useState("");
    const [pincode, setPincode] = useState("");
    const [education, setEducation] = useState("");


    const register_handler = (e) => {
        e.preventDefault();
        //API call

        let notify = notification({ message: "Farmer Registration Successfully!", type: 'success' });
        notify();
    }

    return (
        <div>
            <Header />
            <Page>
                <Row>
                    <Col xs={12}>
                        <h5 className='text-primary'>Farmer Registration Page</h5>
                        <Card color="primary" outline>
                            <CardHeader>Registration</CardHeader>
                            <CardBody>
                                <Form onSubmit={register_handler}>

                                    <Row>
                                        <Col md="3" className='mb-2'>
                                            <Label for="fpoName">Name of FPO</Label>
                                            <Input id="fpoName" bsSize="sm" type="text" value={fpoName} onChange={(e) => setFpoName(e.target.value)} placeholder="FPO Name" required />
                                        </Col>
                                        <Col md="3" className='mb-2'>
                                            <Label for="fName">Name</Label>
                                            <Input id="fName" bsSize="sm" type="text" value={fName} onChange={(e) => setFName(e.target.value)} placeholder="Your Name" required />
                                        </Col>
                                        <Col md="3" className='mb-2'>
                                            <Label for="designation">Designation</Label>
                                            <Input id="designation" bsSize="sm" type="text" value={designation} onChange={(e) => setDesignation(e.target.value)} placeholder="Your Designation" required />
                                        </Col>
                                        <Col md="3" className='mb-2'>
                                            <Label for="gender">Gender</Label>
                                            <Input id="gender" bsSize="sm" type="select" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                                <option value="0"> --Select Gender--</option>
                                                <option value="1"> Male </option>
                                                <option value="2"> Female </option>
                                            </Input>
                                        </Col>
                                        <Col md={3} className="mb-2">
                                            <Label for="mobileNo"> Mobile Number</Label>
                                            <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Your Mobile Number" required />
                                        </Col>
                                        <Col md={3} className="mb-2">
                                            <Label for="education"> Chief Wage Earner : Education </Label>
                                            <Input id="education" bsSize="sm" type="select" value={education} onChange={(e) => setEducation(e.target.value)} required>
                                                <option value=""> --Select Education--</option>
                                                <option value="1"> Illiterate </option>
                                                <option value="2"> School-Upto 4 year </option>
                                                <option value="3"> School-5 to 9 year </option>
                                                <option value="4"> SSC / HS </option>
                                                <option value="5"> Graduate / Post Graduate: General </option>
                                                <option value="6"> Graduate / Post Graduate: Professional </option>
                                            </Input>
                                        </Col>
                                        <Col md={3} className="mb-2">
                                            <Label for="state"> State </Label>
                                            <Input id="state" bsSize="sm" type="select" value={state} onChange={(e) => setState(e.target.value)} required>
                                                <option value=""> --Select State--</option>
                                                <option value="1"> BIHAR </option>
                                                <option value="2"> West Bangel </option>
                                                <option value="3"> Jharkhand </option>

                                            </Input>
                                        </Col>
                                        <Col md={3} className="mb-2">
                                            <Label for="district"> District </Label>
                                            <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => setDistrict(e.target.value)} required>
                                                <option value=""> --Select District--</option>
                                                <option value="1"> District 1 </option>
                                                <option value="2"> District 2 </option>
                                            </Input>
                                        </Col>
                                        <Col md={3} className="mb-2">
                                            <Label for="block"> Block </Label>
                                            <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => setBlock(e.target.value)} required>
                                                <option value=""> --Select Block--</option>
                                                <option value="1"> Block 1 </option>
                                                <option value="2"> Block 2 </option>
                                                <option value="3"> Block 3 </option>
                                            </Input>
                                        </Col>
                                        <Col md={3} className="mb-2">
                                            <Label for="panchayat"> Panchayat </Label>
                                            <Input id="panchayat" bsSize="sm" type="select" value={panchayat} onChange={(e) => setPanchayat(e.target.value)} required>
                                                <option value=""> --Select Panchayat--</option>
                                                <option value="1"> Panchayat 1 </option>
                                                <option value="2"> Panchayat 2 </option>
                                                <option value="3"> Panchayat 3 </option>
                                                <option value="4"> Panchayat 4 </option>
                                            </Input>
                                        </Col>
                                        <Col md="3" className="mb-2">
                                            <Label for="village"> Village </Label>
                                            <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)} required>
                                                <option value=""> --Select Village--</option>
                                                <option value="1"> Village 1 </option>
                                                <option value="2"> Village 2 </option>
                                                <option value="3"> Village 3 </option>
                                                <option value="4"> Village 4 </option>
                                            </Input>
                                        </Col>
                                        <Col md={3} className="mb-2">
                                            <Label for="pincode"> Pincode </Label>
                                            <Input id="pincode" type='text' bsSize="sm" maxLength={6} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={pincode} onChange={(e) => setPincode(e.target.value)} required placeholder="Pincode" />
                                        </Col>
                                        <Col xs={12} className="text-end">
                                            <Button color='primary' size="sm"> <BsFillArrowRightCircleFill /> Register </Button>
                                        </Col>
                                    </Row>
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
            <Footer />
        </div>
    )
}

export default FarmerRegistration;