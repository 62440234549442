import React, { useState, useEffect } from 'react';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';


import axios from 'axios';
import { mainUrl } from '../../MainUrl';
import DataTable from 'react-data-table-component';

import {
    Row, Col, Card, CardHeader, CardBody, Button, CardFooter,
} from 'reactstrap';
import ExcelExport from '../../component/utility/ExcelExport';


const FpoList = () => {

    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [fpoList, setFpoList] = useState('');
    const [farmerList, setFarmerList] = useState('');

    useEffect(() => {
        get_fpo_list();
    }, []);

    const get_fpo_list = () => {

        axios.post(mainUrl + 'mis/getFpoList')
            .then(function (response) {
                if (response.data.status === 1) {

                    let result = response.data.data;

                    let list = result.map((row, index) => {
                        return (
                            {
                                sl: index + 1,
                                fpo_code: row.fpo_code,
                                fpo_name: row.fpo_name,
                                contact_person: row.contact_person,
                                designation: row.designation_name,
                                mobile_no: row.mobile_no,
                                reg_date: row.reg_date,
                                action: <Button color='primary' size="sm" onClick={() => get_farmer_list(row.fpo_code)}> Farmer List </Button>
                            }
                        )
                    })

                    setFpoList(list);

                }
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_farmer_list = (fpoId) => {
        let postData = { "fpo_code": fpoId }
        axios.post(mainUrl + 'mis/getFarmerList', postData)
            .then(function (response) {
                if (response.data.status === 1) {

                    let result = response.data.data;

                    let list = result.map((row, index) => {
                        return (
                            {
                                sl: index + 1,
                                fpo_name: row.fpo_name,
                                farmer_registration: row.farmer_reg,
                                farmer_name: row.name,
                                gender: row.gender_details,
                                mobile_no: row.mobile_no,
                                education: row.education_detail,
                                reg_date: row.reg_date,

                            }
                        )
                    })

                    setFarmerList(list);
                    setMode(2);

                }
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    return (
        <div>
            <Header />
            <Page>
                <Row>
                    {
                        mode === 1 &&
                        <Col xs={12}>
                            <h5 className='text-primary'>FPO List</h5>
                            <Card color="primary" outline>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                            },
                                            {
                                                name: 'FPO Code',
                                                selector: row => row.fpo_code,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Name',
                                                selector: row => row.fpo_name,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Contact Person',
                                                selector: row => row.contact_person,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Designation',
                                                selector: row => row.designation,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Mobile No',
                                                selector: row => row.mobile_no,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Reg Date',
                                                selector: row => row.reg_date,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Action',
                                                selector: row => row.action,
                                                sortable: false,
                                            },
                                        ]}
                                        data={fpoList}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    }
                    {
                        mode === 2 &&
                        <Col xs={12}>
                            <Row className='mb-3'>
                                <Col xs={6}>
                                    <h5 className='text-primary'>Farmer List</h5>
                                </Col>
                                <Col xs={6} className='text-end'>
                                    { (farmerList && farmerList.length > 0) &&
                                        <ExcelExport data={farmerList} name="Farmer_list" />
                                    }
                                    <Button className='ms-3' color='secondary' size="sm" onClick={() => setMode(1)}> Back </Button>
                                </Col>
                            </Row>
                            
                            <Card color="primary" outline>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                                width: '120px'
                                            },
                                            {
                                                name: 'FPO Name',
                                                selector: row => row.fpo_name,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Farmer Registration',
                                                selector: row => row.farmer_registration,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Farmer Name',
                                                selector: row => row.farmer_name,
                                                sortable: false,
                                                wrap: true
                                            },
                                            {
                                                name: 'Gender',
                                                selector: row => row.gender,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Mobile No',
                                                selector: row => row.mobile_no,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Education',
                                                selector: row => row.education,
                                                sortable: false,
                                                wrap: true,
                                            },
                                            {
                                                name: 'Reg Date',
                                                selector: row => row.reg_date,
                                                sortable: false,
                                                wrap: true,
                                            },
                                        ]}
                                        data={farmerList}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    }
                </Row>
            </Page>
            <Footer />
        </div>
    )
}

export default FpoList;