import React, { useState } from 'react';

import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';

import {
    Row, Col, Card, CardHeader, CardBody
} from 'reactstrap';


const Page_2 = () => {
    
    return (
        <div>
            <Header />
            <Page>
                <Row>
                    <Col xs={12}>
                        <h5 className='text-primary'>Page - 2</h5>
                        <Card color="primary" outline>
                            <CardHeader>Header</CardHeader>
                            <CardBody>
                                Sample page
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
            <Footer />
        </div>
    )
}

export default Page_2;