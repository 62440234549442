import React from 'react';
import { notification } from '../hocs/notification';

import * as XLSX from 'xlsx/xlsx.mjs';
import {SiMicrosoftexcel} from 'react-icons/si';
import { Button } from 'reactstrap';

const ExcelExport = ({data, name, classes, ...props}) => {

    const download_excel_handler = () => {
        
        if(data.length > 0){
          let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.json_to_sheet(data);
    
          XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
    
          XLSX.writeFile(wb, name +".xlsx");
        }else{
            let notify = notification({ message: "No Data found! Try Again", type: 'error' });
            notify();
        }
    }

    return(
        <Button className={classes} size='sm' color='primary' onClick={download_excel_handler} >
            <SiMicrosoftexcel className='me-2' size={20}/> 
            Export in excel
        </Button>
    )
}

export default ExcelExport;